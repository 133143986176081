import { FeatherIcon } from "../../../common/static/icons";

const CustomCell = ({ text, onClick }) => {
  return (
    <div className="admTableCell">
      <div className="tableIcon" onClick={onClick}>
        <FeatherIcon icon="edit-2" />
      </div>
      {text}
    </div>
  );
};

export default CustomCell;
