import { Row } from "antd";
import React from "react";

const ActionBar = ({ buttons = [] }) => {
  return (
    <Row align="middle" justify="end" style={{ marginBottom: 20 }}>
      {buttons.map((Btn, index) => (
        <div key={index} style={{ margin: "8px" }}>
          {Btn}
        </div>
      ))}
    </Row>
  );
};

export default ActionBar;
