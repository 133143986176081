import "./index.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { routes } from "./presentation/common/Routes";
import Dash from "./presentation/Dash";
import Home from "./presentation/Home";
import Login from "./presentation/Auth/Login";
import Authenticator from "./presentation/Auth/Authenticator";
import Shops from "./presentation/Shop";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={routes.LOGIN} component={Login} />
        <Authenticator>
          <Route exact path={routes.INITIAL} component={Home} />
          <Route exact path={routes.DASH(":type")} component={Dash} />
          <Route exact path={routes.SHOPS(":type")} component={Shops} />
        </Authenticator>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
