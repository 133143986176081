import { createStore, createHook } from "react-sweet-state";
import { actions } from "./actions";
import { initialState } from "./initialState";

const ShopStore = createStore({
  name: `revyu_admin_stores`,
  initialState,
  actions,
});

export const useShopStore = createHook(ShopStore, {
  selector: (state) => state,
});
