import { FeatherIcon } from "../../static/icons";
import "./index.scss";
import { Tooltip } from "antd";
import { routes } from "../../Routes";
const LogoutBtn = () => {
  return (
    <Tooltip title="Logout" placement="left">
      <div
        className="admLogout"
        onClick={() => {
          window.localStorage.removeItem("loggedIn");
          window.location.href = routes.LOGIN;
        }}
      >
        <FeatherIcon icon="log-out" size={24} />
      </div>
    </Tooltip>
  );
};

export default LogoutBtn;
