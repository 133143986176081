import { Alert, Modal, Radio, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { plans } from "../../../../common/constants";
import { useShopStore } from "../../store";
import { IntegerInput, SelectInput } from "./InputFields";

const UpdateModal = () => {
  const [newValue, setNewValue] = useState("");
  const [controlType, setControlType] = useState("");
  const [
    { controlModal, updatingControl },
    { setControlModal, handleUpdateControls },
  ] = useShopStore();

  const props = { newValue, setNewValue };

  useEffect(() => {
    if (controlModal) {
      setNewValue(
        controlModal?.prevValue === "Enabled"
          ? true
          : controlModal?.prevValue === "Disabled"
          ? false
          : controlModal?.prevValue
      );
      setControlType(controlModal?.key?.split("_")[0]);
    }
  }, [controlModal]);

  return (
    <Modal
      centered
      title={
        <Row align="middle">
          <p style={{ textTransform: "capitalize", margin: 0 }}>
            {controlType}
          </p>
          &nbsp; - &nbsp; <Tag color={"green"}>{controlModal?.prevValue}</Tag>
        </Row>
      }
      onCancel={() => setControlModal(null)}
      visible={controlModal}
      okText="Save"
      okButtonProps={{ loading: updatingControl }}
      onOk={() => handleUpdateControls(newValue)}
    >
      {controlType === "counters" ? (
        <IntegerInput {...props} placeholder="Enter the new no of credits" />
      ) : controlType === "plan" ? (
        <SelectInput {...props} options={plans} />
      ) : controlType === "branding" || "aiEnabled" ? (
        <Row align="middle" justify="center">
          <Radio.Group
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            options={[
              { label: "Activate", value: true },
              { label: "Deactivate", value: false },
            ]}
            optionType="button"
            buttonStyle="solid"
          />
        </Row>
      ) : (
        <Alert showIcon type="warning" message="Feature release on the way!" />
      )}
    </Modal>
  );
};

export default UpdateModal;
