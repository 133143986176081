import { Card, Row, Tag } from "antd";
import React from "react";
import { FeatherIcon } from "../../common/static/icons";
import { useShopStore } from "../store";

const ControlCard = ({ title, content, color = "blue", type = "" }) => {
  const [, { setControlModal }] = useShopStore();

  return (
    <Card
      style={{ margin: 10, width: "fit-content", minWidth: 300 }}
      // title={title}
      actions={[
        <Tag color={color}>
          <b style={{ margin: 0, padding: 3, fontSize: 15, color }}>
            {content}
          </b>
        </Tag>,
        <FeatherIcon
          onClick={() => setControlModal({ key: type, prevValue: content })}
          size={18}
          icon="edit-2"
          style={{ width: "100%" }}
        />,
      ]}
    >
      <Row justify="center">
        <b>{title}</b>
      </Row>
    </Card>
  );
};

const ControlCards = () => {
  const [{ shopData }] = useShopStore();

  return (
    <Row align="middle" justify="center" wrap>
      <ControlCard
        type={"plan_plan"}
        title="Plan"
        content={shopData?.storeSettings?.billing?.subscriptionType}
      />
      <ControlCard
        type={"branding_branding"}
        title="Branding"
        content={shopData?.storeSettings?.brandFlag ? "Enabled" : "Disabled"}
        color={shopData?.storeSettings?.brandFlag ? "green" : "red"}
      />
      <ControlCard
        type={"aiEnabled_aiEnabled"}
        title="Ai Enabled"
        content={shopData?.storeSettings?.aiEnabled ? "Enabled" : "Disabled"}
        color={shopData?.storeSettings?.aiEnabled ? "green" : "red"}
      />
      <ControlCard
        type="counters_credits"
        title="Credits Remaining"
        content={shopData?.storeSettings?.counters?.credits}
      />
      <ControlCard
        type="counters_reqEmails"
        title="Review Request Mails Remaining"
        content={shopData?.storeSettings?.counters?.reqEmails}
      />
      <ControlCard
        title="App Enabled"
        content={shopData?.storeSettings?.active ? "Enabled" : "Disabled"}
        color={shopData?.storeSettings?.active ? "green" : "red"}
      />
      <ControlCard
        title="Popup Enabled"
        content={
          shopData?.storeSettings?.popup?.popupEnabled ? "Enabled" : "Disabled"
        }
        color={shopData?.storeSettings?.popup?.popupEnabled ? "green" : "red"}
      />
      {Object.keys(shopData?.storeSettings?.review?.types).map((type) => {
        const isEnabled = shopData?.storeSettings?.review?.types[type];
        return (
          <ControlCard
            key={type}
            title={`${
              type === "home"
                ? "Home Review"
                : type === "product"
                ? "Product Review"
                : type === "store"
                ? "Store Review"
                : type === "productRating"
                ? "Product Rating"
                : ""
            } Widget Enabled`}
            content={isEnabled ? "Enabled" : "Disabled"}
            color={isEnabled ? "green" : "red"}
          ></ControlCard>
        );
      })}
    </Row>
  );
};

export default ControlCards;
