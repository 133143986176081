import { routes } from "../common/Routes";
import LogoutBtn from "../common/UI/LogoutBtn";
import Card from "./Card";
import "./index.scss";

const Home = () => {
  return (
    <div className="homeMain">
      <LogoutBtn />
      <div className="cardHolder">
        <Card
          onClick={() => (window.location.href = routes.SHOPS("dev"))}
          type="Dev"
        />
        <Card
          onClick={() => (window.location.href = routes.SHOPS("prod"))}
          type="Prod"
        />
      </div>
    </div>
  );
};

export default Home;
