import { includeFilter, numericalSort } from "../../../common/utils";
import moment from "moment";
import { Tag } from "antd";

const columns = () => [
  {
    key: "date",
    dataIndex: "date",
    title: "Date",
    render: (date) => moment(date).format("DD MMM YYYY, hh:mm"),
    sorter: (a, b) => numericalSort(a.date, b.date),
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    filters: [
      { text: "Success", value: "success" },
      { text: "Failed", value: "failed" },
    ],
    onFilter: (value, record) => includeFilter(record?.status, value),
    render: (status) => (
      <Tag
        color={
          status === "success"
            ? "green"
            : status === "failed"
            ? "red"
            : "yellow"
        }
        style={{ textTransform: "capitalize" }}
      >
        {status}
      </Tag>
    ),
  },
  {
    key: "amount",
    dataIndex: "amount",
    title: "Amount",
    render: (amount) => `$ ${amount}`,
    sorter: (a, b) => numericalSort(a.amount, b.amount),
  },
  {
    key: "credits",
    dataIndex: "credits",
    title: "Credits",
    sorter: (a, b) => numericalSort(a.credits, b.credits),
  },
];

export default columns;
