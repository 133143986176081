import {
  copyToClipboard,
  includeFilter,
  numericalSort,
} from "../../../common/utils";
import moment from "moment";
import { Row, Tag, Tooltip } from "antd";
import { FeatherIcon } from "../../../common/static/icons";

const columns = () => [
  {
    key: "date",
    dataIndex: "date",
    title: "Date",
    render: (date) => moment(date).format("DD MMM YYYY, hh:mm"),
    sorter: (a, b) => numericalSort(a.date, b.date),
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    filters: [
      { text: "Success", value: "success" },
      { text: "Failed", value: "failed" },
    ],
    onFilter: (value, record) => includeFilter(record?.status, value),
    render: (status) => (
      <Tag
        color={
          status === "success"
            ? "green"
            : status === "failed"
            ? "red"
            : "yellow"
        }
        style={{ textTransform: "capitalize" }}
      >
        {status}
      </Tag>
    ),
  },
  {
    key: "type",
    dataIndex: "type",
    title: "Type",
    filters: [
      { text: "PitchGround", value: "pitchground" },
      { text: "AppSumo", value: "appsumo" },
    ],
    onFilter: (value, record) => includeFilter(record?.type, value),
    render: (type) => <p style={{ textTransform: "capitalize" }}>{type}</p>,
    sorter: (a, b) => numericalSort(a.credits, b.credits),
  },
  {
    key: "code",
    dataIndex: "code",
    title: "Code",
    sorter: (a, b) => numericalSort(a.amount, b.amount),
  },
  {
    key: "actions",
    title: "Actions",
    render: (record) => (
      <Row>
        <Tooltip title="Copy Coupon Code">
          <p style={{ margin: 0 }}>
            <FeatherIcon
              size={16}
              icon="copy"
              onClick={() => copyToClipboard(record?.code)}
            />
          </p>
        </Tooltip>
      </Row>
    ),
  },
];

export default columns;
