const getData = (shopData) => {
  const data = [];
  const products = shopData?.products;
  Object.keys(products).map((productId) =>
    data.push({
      productId,
      productName: products[productId]?.title || "Unknown",
      reviews: shopData?.reviews?.[productId] || [],
      rating: products[productId]?.average?.avgRating || "0.0",
      handle: products[productId]?.handle,
    })
  );
  return data;
};

export default getData;
