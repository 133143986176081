import { Row, Tooltip } from "antd";
import { FeatherIcon } from "../../../common/static/icons";
import {
  alphabeticalSort,
  copyToClipboard,
  numericalSort,
} from "../../../common/utils";

const columns = (shopData) => [
  {
    key: "id",
    dataIndex: "productId",
    title: "Product ID",
    sorter: (a, b) => alphabeticalSort(a.productId, b.productId),
  },
  {
    key: "name",
    dataIndex: "productName",
    title: "Product Name",
    sorter: (a, b) => alphabeticalSort(a.productName, b.productName),
  },
  {
    key: "reviews",
    title: "Reviews",
    render: (record) => <p>{record?.reviews?.length} Reviews</p>,
    sorter: (a, b) => numericalSort(a.reviews?.length, b.reviews?.length),
  },
  {
    key: "rating",
    dataIndex: "rating",
    title: "Rating",
    sorter: (a, b) => numericalSort(a.rating, b.rating),
  },
  {
    key: "actions",
    title: "Actions",
    render: (record) => (
      <Row>
        <Tooltip title="Copy Product id">
          <p style={{ margin: 0 }}>
            <FeatherIcon
              size={16}
              icon="copy"
              onClick={() => copyToClipboard(record?.productId)}
            />
          </p>
        </Tooltip>{" "}
        &nbsp; &nbsp;
        <Tooltip title="View in shopify">
          <a
            href={
              record?.productId === "store"
                ? `https://${shopData?.storeDetails?.name}.myshopify.com/`
                : `https://${shopData?.storeDetails?.name}.myshopify.com/products/${record?.handle}`
            }
            target="__blank"
          >
            <FeatherIcon style={{ cursor: "pointer" }} size={16} icon="eye" />
          </a>
        </Tooltip>
      </Row>
    ),
  },
];
export default columns;
