import "./index.scss";
import { Button, Divider, message, Modal, Row, Select } from "antd";
import React, { useState } from "react";
import { useShopStore } from "../../store";

const SubscribeWebhoks = ({ visible, setVisibility }) => {
  const [
    { shops, subscribeLogs, subscribing },
    { handleSubscribeWebhooks, setSubscribeLogs },
  ] = useShopStore();
  const [selectedTopics, setSelectedTopics] = useState(["all"]);
  const [selectedShops, setSelectedShops] = useState(["all"]);
  const [viewLogs, setviewLogs] = useState(false);

  const WebhookTopics = [
    "orders/create",
    "orders/fulfilled",
    "orders/paid",
    "themes/update",
    "products/delete",
  ];

  const handleOnSelect = (values, item) => {
    let newValues = [];
    if (values[values.length - 1] === "all") {
      newValues = ["all"];
    } else {
      if (values.includes("all") && values.length > 1) {
        newValues = values.filter((item) => item !== "all");
      } else {
        newValues = values;
      }
    }

    if (item === "topic") setSelectedTopics(newValues);
    else if (item === "shop") setSelectedShops(newValues);
  };

  const handleSubmit = async () => {
    if (selectedTopics.length <= 0)
      message.error("Please select atleast 1 webhook");
    else if (selectedShops.length <= 0)
      message.error("Please select atleast 1 store");
    else {
      let finalShops = [],
        finalTopics = [];
      if (selectedShops.includes("all"))
        finalShops = shops.map((shop) => shop.shopName);
      else finalShops = selectedShops;
      if (selectedTopics.includes("all")) finalTopics = WebhookTopics;
      else finalTopics = selectedTopics;
      await handleSubscribeWebhooks(finalShops, finalTopics);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Subscribe to webhooks"
      closable={!subscribing}
      footer={null}
      centered
      onCancel={() => {
        setSelectedShops(["all"]);
        setSelectedTopics(["all"]);
        setviewLogs(false);
        setSubscribeLogs([]);
        setVisibility(false);
      }}
      destroyOnClose
      maskClosable={!subscribing}
    >
      <label>Select Preferred Webhook</label>
      <Select
        size="large"
        style={{ width: "100%" }}
        mode="multiple"
        defaultValue={selectedTopics}
        value={selectedTopics}
        onChange={(values) => handleOnSelect(values, "topic")}
        allowClear
        showSearch
      >
        <Select.Option key="all" value="all">
          All
        </Select.Option>
        {WebhookTopics.map((webhookTopic) => (
          <Select.Option key={webhookTopic} value={webhookTopic}>
            {webhookTopic}
          </Select.Option>
        ))}
      </Select>
      <br />
      <br />
      <label>Select Stores</label>
      <Select
        size="large"
        style={{ width: "100%" }}
        mode="multiple"
        defaultValue={selectedShops}
        value={selectedShops}
        onChange={(values) => handleOnSelect(values, "shop")}
        allowClear
        showSearch
      >
        <Select.Option key="all" value="all">
          All
        </Select.Option>
        {shops.map((shop) => (
          <Select.Option key={shop.shopName} value={shop.shopName}>
            {shop.shopName}
          </Select.Option>
        ))}
      </Select>
      <br />
      <br />
      <Row justify="space-between" align="middle">
        <Button onClick={() => setviewLogs((prev) => !prev)} type="link">
          {viewLogs ? "Hide" : "View"} Logs
        </Button>
        <Button
          size="large"
          type="primary"
          loading={subscribing}
          onClick={handleSubmit}
        >
          Subscribe to Webhooks
        </Button>
      </Row>
      <div
        className={`subLogsCont subLogsCont-${viewLogs ? "open" : "closed"}`}
      >
        <Divider />
        <div className="subLogs">
          {subscribeLogs.map((log) => (
            <div className="subLog">
              <span
                className={log.success ? "subLog-stsGreen" : "subLog-stsRed"}
              >
                {log.success ? "[Success]" : "[Failed]"}
              </span>
              <p className="subLog-msg">{log?.msg}</p>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default SubscribeWebhoks;
