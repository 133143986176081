import { Button, Input, message, Result, Row, Tabs } from "antd";
import React, { useState } from "react";
import Main from "../Layout";
import ProductsTable from "./common/ProductsTable";
import ShopDetails from "./common/ShopDetails";
import PaymentsTable from "./common/PaymentTable";
import CouponsTable from "./common/CouponsTable";
import ControlCards from "./common/ControlCards";
import { FeatherIcon } from "../common/static/icons";
import { useShopStore } from "./store";
import UpdateModal from "./common/UpdateModal";

const Shops = () => {
  const [{ loadingPercent }, { handleGetStoreData }] = useShopStore();
  const [storeName, setStoreName] = useState("");

  return (
    <Main>
      {/* <ActionBar
        title={"Shop Details"}
        buttons={[
          <Button
            onClick={() =>
              message.warn("Too expensive! Currenlty unavailable.")
            }
            size="large"
            type="primary"
          >
            Load All Stores
          </Button>,
        ]}
      /> */}
      <div>
        <Row justify="center" style={{ margin: 20 }}>
          <Input
            size="large"
            style={{ maxWidth: 350, margin: 10 }}
            placeholder="Enter Store Name"
            name="storeName"
            onChange={(e) => setStoreName(e.target.value)}
            addonBefore={<FeatherIcon icon="search" />}
          />
          <Button
            loading={loadingPercent > 0 && loadingPercent < 100}
            onClick={() => {
              storeName
                ? handleGetStoreData(storeName)
                : message.info(
                    `Our job would be easier if you provide the shop name`
                  );
            }}
            size="large"
            type="danger"
            style={{ margin: 10 }}
          >
            Get Shop Details
          </Button>
        </Row>
        <Row justify="center">
          {loadingPercent === 0 ? (
            <Result
              icon={
                <FeatherIcon
                  size={32}
                  style={{ color: "dodgerblue" }}
                  icon="search"
                />
              }
              title="Stop staring and search something!"
            />
          ) : loadingPercent < 0 ? (
            <Result
              status="error"
              title={
                loadingPercent === -1
                  ? "Store not found. We cannot guess, spell correctly!"
                  : "Some error occured. Report to the dev team!"
              }
            />
          ) : loadingPercent < 100 ? (
            <Result
              status="info"
              title={"Hold on! We're searching for that store."}
            />
          ) : (
            <></>
          )}
        </Row>
        {loadingPercent >= 100 && (
          <>
            <Row justify="center">
              <ShopDetails />
            </Row>
            <div>
              <Tabs>
                <Tabs.TabPane tab="Controls" key="0">
                  <ControlCards />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Products" key="1">
                  <ProductsTable />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Coupon History" key="2">
                  <CouponsTable />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Payment History" key="3">
                  <PaymentsTable />
                </Tabs.TabPane>
              </Tabs>
            </div>
            <UpdateModal />
          </>
        )}
      </div>
    </Main>
  );
};

export default Shops;
