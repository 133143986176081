import { Table, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import React from "react";
import { useShopStore } from "../../store";
import { downloadProductReviewAsCsv } from "../../../common/utils/download";
import columns from "./columns";
import getData from "./data";

const DownloadButton = ({reviews,storeName, productId }) => (
  <Button
    type="link"
    icon={<DownloadOutlined />} // You can replace this with the appropriate icon
    onClick={() => {
      downloadProductReviewAsCsv(reviews, storeName, productId);
    }}
  />
);

const ProductsTable = () => {
  const [{ shopData }] = useShopStore();
  const reviews = shopData?.reviews ?? {};

  const downloadColumn = {
    title: "Download as CSV",
    key: "download",
    align: "center",
    render: (text, record) => (
      <DownloadButton reviews={reviews} storeName={shopData?.storeName} productId={record.productId} />
    ),
  };
 

  const enhancedColumns = [...columns(shopData), downloadColumn];

  return (
    <Table
      rowKey={(shop) => shop.productId}
      columns={enhancedColumns}
      dataSource={getData(shopData)}
      pagination={false}
      // pagination={{
      //   defaultPageSize: 10,
      //   total: shopData?.products?.length,
      //   showTotal: (total, range) =>
      //     `${range[0]}-${range[1]} of ${total} products`,
      // }}
    />
  );
};

export default ProductsTable;
