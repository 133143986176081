import "./index.scss";
import { Alert, Button, Row, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import Main from "../Layout";
import ChangeCredits from "./common/ChangeCredits";
import ChangePlan from "./common/ChangePlan";
import { storesColumns } from "./common/Columns";
import { useShopStore } from "./store";
import ActionBar from "../common/UI/ActionBar";
import SubscribeWebhoks from "./common/Subscribe";

const Stores = () => {
  const [{ loadingShops, shops }, { handleGetShops }] = useShopStore();
  const [subsModal, setsubsModal] = useState(false);

  useEffect(() => {
    shops === null && handleGetShops();
    // eslint-disable-next-line
  }, [handleGetShops]);

  return (
    <Main>
      <Alert
        type="warning"
        showIcon
        message="Do not refresh frequently. Too expensive!"
        style={{ width: "fit-content", margin: "auto" }}
      />
      {loadingShops ? (
        <Row justify="center" align="middle" style={{ height: "75vh" }}>
          <Spin tip="Loading shops..." />
        </Row>
      ) : (
        <>
          <ActionBar
            buttons={[
              <Button
                size="large"
                type="primary"
                onClick={() => setsubsModal(true)}
              >
                Subscribe Webhooks
              </Button>,
            ]}
          />
          <Table
            rowKey={(shop) => shop.shopName}
            className="admTable"
            loading={loadingShops}
            columns={storesColumns}
            dataSource={shops}
            pagination={{
              defaultPageSize: 10,
              total: shops.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} shops`,
            }}
          />
          <ChangePlan />
          <ChangeCredits />
          <SubscribeWebhoks visible={subsModal} setVisibility={setsubsModal} />
        </>
      )}
    </Main>
  );
};

export default Stores;
