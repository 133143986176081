import { Table } from "antd";
import React from "react";
import { useShopStore } from "../../store";
import columns from "./columns";
import getData from "./data";

const CouponsTable = () => {
  const [{ shopData }] = useShopStore();

  return (
    <Table
      rowKey={(shop) => shop.productId}
      columns={columns(shopData)}
      dataSource={getData(shopData)}
      pagination={{
        defaultPageSize: 10,
        total: shopData?.payments?.length,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} payments`,
      }}
    />
  );
};

export default CouponsTable;
