import { message } from "antd";
import config from "../../../common/config";
import { logError } from "../../../common/utils";
import {
  getStores,
  getToken,
  setStoreCredits,
  setStorePlan,
  subscribeWebhook,
} from "../../../infrastructure/store";

export const actions = {
  setShops:
    (shops) =>
    ({ setState }) => {
      setState({ shops });
    },

  setSubscribing:
    (subscribing) =>
    ({ setState }) => {
      setState({ subscribing });
    },

  setSubscribeLogs:
    (subscribeLogs) =>
    ({ setState }) => {
      setState({ subscribeLogs });
    },

  setLoadingShops:
    (loadingShops) =>
    ({ setState }) => {
      setState({ loadingShops });
    },

  setChangingPlan:
    (changingPlan) =>
    ({ setState }) => {
      setState({ changingPlan });
    },

  setChangingCredits:
    (changingCredits) =>
    ({ setState }) => {
      setState({ changingCredits });
    },

  setChangePlanModal:
    (changePlanModal) =>
    ({ setState }) => {
      setState({ changePlanModal });
    },

  setChangeCreditModal:
    (changeCreditModal) =>
    ({ setState }) => {
      setState({ changeCreditModal });
    },

  handleGetShops:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingShops(true));
      try {
        const shopsRaw = await getStores();
        let shopsData = {},
          shops = [];
        if (shopsRaw.exists()) {
          shopsData = shopsRaw.val();
        }
        Object.keys(shopsData).map((shopName) => {
          if (!shopName.startsWith("000_"))
            return shops.push({
              shopName,
              plan: shopsData[shopName]?.storeSettings?.billing
                ?.subscriptionType,
              credits: shopsData[shopName]?.storeSettings?.counters?.credits,
            });
          return null;
        });
        dispatch(actions.setShops(shops));
      } catch (error) {
        logError(error, "@ handleGetShops()");
      } finally {
        dispatch(actions.setLoadingShops(false));
      }
    },

  handleChangePlan:
    (newPlan, shopName) =>
    async ({ getState, dispatch }) => {
      if (newPlan) {
        dispatch(actions.setChangingPlan(true));
        try {
          await setStorePlan(shopName, newPlan);
          let { shops } = getState();
          const index = shops?.map((item) => item.shopName)?.indexOf(shopName);
          if (index >= 0) shops[index].plan = newPlan;
          dispatch(actions.setShops(shops));
          dispatch(actions.setChangePlanModal(null));
          message.success("Plan updated !");
        } catch (error) {
          logError(error, "@ handleChangePlan()");
        } finally {
          dispatch(actions.setChangingPlan(false));
        }
      } else {
        message.error("Please select a plan !");
      }
    },

  handleChangeCredits:
    (newCredits, shopName) =>
    async ({ getState, dispatch }) => {
      if (newCredits && newCredits >= 0) {
        dispatch(actions.setChangingCredits(true));
        try {
          await setStoreCredits(shopName, newCredits);
          let { shops } = getState();
          const index = shops?.map((item) => item.shopName)?.indexOf(shopName);
          if (index >= 0) shops[index].credits = newCredits;
          dispatch(actions.setShops(shops));
          dispatch(actions.setChangeCreditModal(null));
          message.success("Credits updated !");
        } catch (error) {
          logError(error, "@ handleChangeCredits()");
        } finally {
          dispatch(actions.setChangingCredits(false));
        }
      } else {
        message.error("Please add some credits !");
      }
    },

  handleSubscribeWebhook:
    (shop, topic) =>
    async ({ getState, dispatch }) => {
      const token = await getToken(shop);
      if (!token.exists()) return message.error("Token not found!");
      const res = await subscribeWebhook(
        shop,
        topic,
        token?.val(),
        config.WEBHOOK_API
      );
      const { subscribeLogs } = getState();
      dispatch(
        actions.setSubscribeLogs([
          {
            msg: `${shop}${
              res.status === 203 ? " already" : res.status !== 200 ? " not" : ""
            } subscribed to ${topic}`,
            success: res.status === 200 || res.status === 203,
          },
          ...subscribeLogs,
        ])
      );
    },

  handleSubscribeWebhooks:
    (shops = [], topics = []) =>
    async ({ dispatch }) => {
      dispatch(actions.setSubscribing(true));
      const promises = [];
      try {
        shops.map((shop) =>
          topics.map((topic) => {
            return promises.push(
              dispatch(actions.handleSubscribeWebhook(shop, topic))
            );
          })
        );
        await Promise.all(promises);
        message.success("Subscription complete!");
      } catch (error) {
        logError(error, "@ handleSubscribeWebhooks()");
        message.error("Error in webhook subscription!");
      } finally {
        dispatch(actions.setSubscribing(false));
      }
    },
};
