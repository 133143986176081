import { Col, InputNumber, Modal } from "antd";
import { useEffect, useState } from "react";
import { useShopStore } from "../../store";

const ChangeCredits = () => {
  const [
    { changeCreditModal, changingCredits, shops },
    { setChangeCreditModal, handleChangeCredits },
  ] = useShopStore();
  const [newCredits, setNewCredits] = useState("");

  useEffect(() => {
    setNewCredits(
      shops.find((item) => item?.shopName === changeCreditModal)?.credits
    );
  }, [changeCreditModal, shops]);

  return (
    <Modal
      centered
      title={`Change Credits : ${changeCreditModal}`}
      onCancel={() => setChangeCreditModal(null)}
      visible={changeCreditModal}
      okText="Save"
      okButtonProps={{ loading: changingCredits }}
      onOk={() => handleChangeCredits(newCredits, changeCreditModal)}
    >
      <Col justify="center">
        <h3>Enter Credits</h3>
        <InputNumber
          min={0}
          max={100000}
          value={newCredits}
          onChange={(value) => setNewCredits(value)}
          step={1000}
        />
      </Col>
    </Modal>
  );
};

export default ChangeCredits;
