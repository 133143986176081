const getData = (shopData) => {
  const data = [];
  const payments = shopData.paymentLogs;
  Object.keys(payments).map((token) =>
    data.push({
      token,
      date: payments[token]?.date,
      status: payments[token]?.status,
      amount: payments[token]?.amount,
      credits: payments[token]?.credits,
    })
  );
  return data?.reverse();
};

export default getData;
