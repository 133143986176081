import "./index.scss";
import { Layout } from "antd";
import { getCurrentEnv } from "../common/utils";
import { Link, useHistory } from "react-router-dom";
import { capitalize } from "../common/utils/text";
import { routes } from "../common/Routes";

const { Content, Header } = Layout;

const Main = ({ children }) => {
  const history = useHistory();

  return (
    <Layout className="admLayout">
      <Header className="admHeader">
        <div className="admHeader-row">
          <Link to={routes.INITIAL}>
            <h3>{capitalize(getCurrentEnv(history))} Admin</h3>
          </Link>
          <span>|</span> <p>Stores</p>
        </div>
      </Header>
      <Content className="admContent">
        <div className="admContent-wrapper">{children}</div>
      </Content>
    </Layout>
  );
};

export default Main;
