export const initialState = {
  shops: null,
  subscribing: false,
  subscribeLogs: [],
  loadingShops: true,
  changingPlan: false,
  changingCredits: false,
  changePlanModal: null,
  changeCreditModal: null,
};
