import { InputNumber, Select } from "antd";

const inputProps = {
  size: "large",
  style: { width: "100%" },
};

const getIntegerValue = (value) => {
  if (parseInt(value)) return parseInt(value);
  else return 0;
};

export const IntegerInput = ({ newValue, setNewValue, placeholder }) => {
  return (
    <InputNumber
      {...inputProps}
      placeholder={placeholder}
      min={0}
      max={100000}
      value={newValue}
      onChange={(value) => setNewValue(getIntegerValue(value))}
      step={1000}
    />
  );
};

export const SelectInput = ({
  newValue,
  setNewValue,
  placeholder,
  options,
}) => {
  return (
    <Select
      {...inputProps}
      onChange={(value) => setNewValue(value)}
      value={newValue}
      placeholder={placeholder}
    >
      {Object.keys(options).map((optionKey) => (
        <Select.Option key={optionKey} value={optionKey}>
          {options[optionKey]?.name}
        </Select.Option>
      ))}
    </Select>
  );
};
