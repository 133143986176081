import { message } from "antd";
import { logError } from "../../../common/utils";
import {
  getStoreData,
  getStoreLogs,
  setAiEnabled,
  setBrandFlag,
  setGenerateIntegration,
  setStoreCredits,
  setStorePlan,
} from "../../../infrastructure/store";

export const actions = {
  setShopData:
    (shopData) =>
    ({ setState }) =>
      setState({ shopData }),

  setLoadingPercent:
    (loadingPercent) =>
    ({ setState }) =>
      setState({ loadingPercent }),

  setControlModal:
    (controlModal) =>
    ({ setState }) =>
      setState({ controlModal }),

  setUpdatingControl:
    (updatingControl) =>
    ({ setState }) =>
      setState({ updatingControl }),

  handleGetStoreData:
    (storeName = "") =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingPercent(1));
      try {
        storeName = storeName?.trim()?.toLowerCase();
        storeName = storeName.replace(/\s\s+/g, " ");
        storeName = storeName.replace(/-\s+/g, "-");
        storeName = storeName.replace(/\s+-/g, "-");
        storeName = storeName.replace(/\s/g, "-");
        const storeDataShot = await getStoreData(storeName);
        dispatch(actions.setLoadingPercent(50));
        const storeLogShot = await getStoreLogs(storeName);
        if (storeDataShot.exists()) {
          let logs = { couponLogs: {}, paymentLogs: {} };
          if (storeLogShot.exists())
            logs = {
              couponLogs: storeLogShot.val()?.coupon || {},
              paymentLogs: storeLogShot.val()?.payments || {},
            };

          dispatch(
            actions.setShopData({ storeName, ...storeDataShot.val(), ...logs })
          );
          dispatch(actions.setLoadingPercent(100));
        } else {
          dispatch(actions.setLoadingPercent(-1));
        }
      } catch (error) {
        message.error(error.toString());
        dispatch(actions.setLoadingPercent(-2));
      }
    },

  handleUpdateControls:
    (newValue) =>
    async ({ getState, dispatch }) => {
      let { controlModal } = getState();
      const controlType = controlModal?.key;
      console.log({
        controlModal,
        controlType,
        spli: controlType?.split("_")[0],
      });
      const controlLabel = controlType?.split("_")[0];
      if (newValue || newValue === false) {
        dispatch(actions.setUpdatingControl(true));
        try {
          const updatedShopData = await dispatch(
            actions.handleExecuteUpdateControl(newValue)
          );
          dispatch(actions.setShopData(updatedShopData));
          dispatch(actions.setControlModal(null));
          message.success(`${controlLabel} updated!`);
        } catch (error) {
          logError(error, "@ handleUpdateControls()");
        } finally {
          dispatch(actions.setUpdatingControl(false));
        }
      } else {
        console.log({ newValue });
        message.error(`Please provide a valid value for ${controlLabel}!`);
      }
    },

  handleExecuteUpdateControl:
    (newValue) =>
    async ({ getState }) => {
      let { shopData, controlModal } = getState();
      const { storeName } = shopData;
      const controlType = controlModal?.key;
      const controlMainType = controlType.split("_")[0],
        controlSubType = controlType.split("_")[1];
      switch (controlMainType) {
        case "plan":
          await setStorePlan(storeName, newValue);
          shopData.storeSettings.billing.subscriptionType = newValue;
          break;
        case "counters":
          await setStoreCredits(storeName, newValue, controlSubType);
          shopData.storeSettings.counters[controlSubType] = newValue;
          break;
        case "branding":
          await setBrandFlag(storeName, newValue);
          shopData.storeSettings.brandFlag = newValue;
          break;
        case "aiEnabled":
          await setAiEnabled(storeName, newValue);
          await setGenerateIntegration(storeName, newValue);
          shopData.storeSettings.aiEnabled = newValue;
          if (shopData.integations) shopData.integations.generate = newValue;
          break;
        default:
          break;
      }

      return shopData;
    },
};
