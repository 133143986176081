export const downloadFile = (fileData, fileName) => {
  var encodedUri = encodeURI(fileData);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

//function to download individual product reviews as csv
export const downloadProductReviewAsCsv = (reviews,storeName,productId) => {
  console.log(`Downloading product with ID: ${productId}`);
  let outputData = "data:text/csv;charset=utf-8,";
  let outputFields = [
    "productId",
    "rating",
    "country",
    "displayName",
    "content",
    "date",
    "email",
    "photos",
  ];
  outputData += outputFields.toString();
  console.log(reviews);
  if (reviews.hasOwnProperty(productId)) {
    reviews[productId].forEach((review) => {
      outputData += "\n";
      outputFields.forEach((field) => {
        if (field === "content")
          outputData += `"${review[field]}",`.split("\n").join("");
        else if (field === "photos")
          outputData += `"${review[field] ? review[field].toString() : ""}",`;
        else outputData += review[field] + ",";
      });
    });
  }

  downloadFile(outputData, `${storeName}_${productId}_reviews.csv`);
};

export const downloadReviewsAsCsv = (reviews, storeName) => {
  let outputData = "data:text/csv;charset=utf-8,";
  let outputFields = [
    "productId",
    "rating",
    "country",
    "displayName",
    "content",
    "date",
    "email",
    "photos",
  ];
  outputData += outputFields.toString();
  Object.keys(reviews).forEach((pdtId) => {
    reviews[pdtId].forEach((review) => {
      outputData += "\n";
      outputFields.forEach((field) => {
        if (field === "content")
          outputData += `"${review[field]}",`.split("\n").join("");
        else if (field === "photos")
          outputData += `"${review[field] ? review[field].toString() : ""}",`;
        else outputData += review[field] + ",";
      });
    });
  });

  downloadFile(outputData, `${storeName}_reviews.csv`);
};
