const getData = (shopData) => {
  const data = [];
  const coupons = shopData.couponLogs;
  Object.keys(coupons).map((timeStamp) =>
    data.push({
      token: timeStamp,
      date: coupons[timeStamp]?.stamp,
      status: coupons[timeStamp]?.status,
      code: coupons[timeStamp]?.code,
      type: coupons[timeStamp]?.type,
    })
  );
  return data?.reverse();
};

export default getData;
