import { primaryDB, secondaryDB } from "../common/firebase";
import { post } from "../common/remote";

export const getStores = () => {
  return primaryDB.ref(`/`).once("value");
};

export const getStoreData = (storeName) => {
  return primaryDB.ref(`${storeName}`).once("value");
};

export const getStoreLogs = (storeName) => {
  return secondaryDB.ref(`000_logs/${storeName}`).once("value");
};

export const setStoreCredits = (shopName, counter, type) => {
  return primaryDB
    .ref(`${shopName}/storeSettings/counters/${type}`)
    .set(counter);
};

export const setStorePlan = (shopName, newPlan) => {
  return primaryDB
    .ref(`${shopName}/storeSettings/billing/subscriptionType`)
    .set(newPlan);
};

export const setBrandFlag = (shopName, newBrandFlag) => {
  return primaryDB.ref(`${shopName}/storeSettings/brandFlag`).set(newBrandFlag);
};

export const setAiEnabled = (shopName, newAiEnabled) => {
  return primaryDB.ref(`${shopName}/storeSettings/aiEnabled`).set(newAiEnabled);
};

export const setGenerateIntegration = (shopName, newGenerateIntegration) => {
  return primaryDB
    .ref(`${shopName}/integations/generate`)
    .set(newGenerateIntegration);
};

export const subscribeWebhook = (shopName, webhookTopic, token, url) => {
  return post(`admin/subscribeWebhook`, {
    shop: shopName,
    topic: webhookTopic,
    token,
    url,
  });
};

export const getToken = (shop) => {
  return primaryDB.ref(`${shop}/storeSettings/token`).once("value");
};
