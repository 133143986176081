import { Col, Modal, Select, Tag } from "antd";
import { useEffect, useState } from "react";
import { plans } from "../../../../common/constants";
import { useShopStore } from "../../store";

const ChangePlan = () => {
  const [
    { changePlanModal, changingPlan, shops },
    { setChangePlanModal, handleChangePlan },
  ] = useShopStore();
  const [newPlan, setnewPlan] = useState("");

  useEffect(() => {
    setnewPlan(shops.find((item) => item?.shopName === changePlanModal)?.plan);
  }, [changePlanModal, shops]);

  return (
    <Modal
      centered
      title={
        <>
          {changePlanModal} - <Tag color={plans[newPlan]?.color}>{newPlan}</Tag>
        </>
      }
      onCancel={() => setChangePlanModal(null)}
      visible={changePlanModal}
      okText="Save"
      okButtonProps={{ loading: changingPlan }}
      onOk={() => handleChangePlan(newPlan, changePlanModal)}
    >
      <Col justify="center">
        <h3>Change Plan</h3>
        <Select
          style={{ width: "100%" }}
          size="large"
          onChange={(value) => setnewPlan(value)}
          value={newPlan}
        >
          {Object.keys(plans).map((planKey) => (
            <Select.Option key={planKey} value={planKey}>
              {plans[planKey]?.name}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Modal>
  );
};

export default ChangePlan;
