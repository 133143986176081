export const plans = {
  FREE: {
    name: "Free",
    color: "default",
  },
  FREEMIUM: {
    name: "Freemium",
    color: "default",
  },
  PRO: {
    name: "Pro",
    color: "blue",
  },
  SMART: {
    name: "Smart",
    color: "red",
  },
  PG_SMART_A: {
    name: "PitchGround Smart A",
    color: "red",
  },
  PG_SMART_B: {
    name: "PitchGround Smart B",
    color: "red",
  },
  PG_SMART_C: {
    name: "PitchGround Smart C",
    color: "red",
  },
  EARLY_BIRD: {
    name: "Early Bird",
    color: "green",
  },
  LEGEND: {
    name: "Legend",
    color: "green",
  },
  LIFE_TIME: {
    name: "Life Time",
    color: "volcano",
  },
  LIFE_TIME_SMART: {
    name: "Life Time Smart",
    color: "green",
  },
};
