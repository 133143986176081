import { useEffect, useState } from "react";
import { routes } from "../common/Routes";
import { PrimaryLoadingIndicator } from "../common/UI/LoadingIndicator";

const Authenticator = ({ children }) => {
  const [authenticating, setAuthenticating] = useState(true);

  useEffect(() => {
    const loggedIn = window.localStorage.getItem("loggedIn");
    if (!loggedIn) {
      window.location.href = routes.LOGIN;
    } else {
      setAuthenticating(false);
    }
  }, []);

  if (authenticating)
    return <PrimaryLoadingIndicator isFullPage text="Authenticating..." />;
  return <>{children}</>;
};

export default Authenticator;
