import { Row } from "antd";
import FeatherIcons from "feather-icons-react";

const FeatherIcon = ({
  icon = "square",
  size = 18,
  className,
  onClick,
  style = {},
}) => {
  return (
    <Row align="middle" justify="center">
      <FeatherIcons
        style={{ cursor: onClick ? "pointer" : "auto", ...style }}
        icon={icon}
        onClick={onClick}
        className={className}
        size={size}
      />
    </Row>
  );
};

export { FeatherIcon };
