import { Tag } from "antd";
import { plans } from "../../../../common/constants";
import { useShopStore } from "../../store";
import CustomCell from "./CustomCell";

export const ChangePlanButton = ({ store }) => {
  const [, { setChangePlanModal }] = useShopStore();
  return (
    <CustomCell
      onClick={() => setChangePlanModal(store?.shopName)}
      text={
        <Tag color={plans[store?.plan]?.color}>
          {String(plans[store?.plan]?.name).toUpperCase()}
        </Tag>
      }
    />
  );
};

export const ChangeCreditButton = ({ store }) => {
  const [, { setChangeCreditModal }] = useShopStore();
  return (
    <CustomCell
      onClick={() => setChangeCreditModal(store?.shopName)}
      text={store?.credits}
    />
  );
};
