import "./index.scss";

const Card = ({ type, onClick }) => {
  return (
    <div className="cardMain" onClick={onClick}>
      <div className="content">
        <h1 style={{ color: `${type === "Prod" ? "#f94144" : "#2b9348"}` }}>
          {type}
        </h1>
      </div>
      <div
        className="footerCard"
        style={{
          backgroundColor: `${type === "Prod" ? "#f94144" : "#2b9348"}`,
        }}
      ></div>
    </div>
  );
};

export default Card;
