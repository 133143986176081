export const getEnv = () => {
  const location = window.location.pathname?.split("/")[1];

  //separate conditions for local host
  if (location === "localhost") {
    return "local";
  } else {
    //subdomain based env selection
    let subDomain = location.split(".")[0];
    switch (subDomain) {
      case "prod":
        return "production";
      case "staging":
        return "staging";
      case "dev":
        return "development";
      case "localhost":
        return "local";
      default:
        return "development";
    }
  }
};
