import { Card, Col, Row, Tag, Tooltip } from "antd";
import React from "react";
import { countries } from "../../../common/constants";
import { FeatherIcon } from "../../common/static/icons";
import { copyToClipboard } from "../../common/utils";
import { useShopStore } from "../store";
import { downloadReviewsAsCsv } from "../../common/utils/download";

const ShopDetails = () => {
  const [{ shopData }] = useShopStore();
  const isThemeApp = shopData?.storeSettings?.themeApp?.themeAppSupport;
  const reviews = shopData?.reviews ?? {};

  const InfoBar = ({ icon, text }) => (
    <Row align="middle" style={{ margin: "5px 0" }}>
      <FeatherIcon
        icon={icon}
        size={16}
        style={{ color: "darkgray", marginRight: 10 }}
      />
      <p style={{ margin: 0, marginRight: 15 }}>{text}</p>
      <FeatherIcon
        size={16}
        onClick={() => copyToClipboard(text)}
        icon="copy"
      />
    </Row>
  );

  const Action = ({ onClick, tooltip, icon }) => {
    return (
      <Tooltip placement="top" title={tooltip}>
        <span>
          <FeatherIcon onClick={onClick} icon={icon} />
        </span>
      </Tooltip>
    );
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Card
        style={{ width: "fit-content", minWidth: 360 }}
        title={
          <Col>
            <Row align="middle">
              <h1 style={{ margin: 0, marginRight: 15, color: "dodgerblue" }}>
                {shopData?.storeName}
              </h1>
              <FeatherIcon
                size={18}
                onClick={() => copyToClipboard(shopData?.storeName)}
                icon="copy"
              />
            </Row>
            <Tag color={isThemeApp ? "green" : "red"}>
              {isThemeApp ? "Theme App" : "Legacy Theme"}
            </Tag>
          </Col>
        }
        actions={[
          <Action
            tooltip="Send email"
            onClick={() =>
              window.open(`mailto:${shopData?.storeDetails?.email}`, "__blank")
            }
            icon="mail"
          />,
          <Action
            tooltip="View store"
            onClick={() =>
              window.open(
                `https://${shopData?.storeDetails?.domain}`,
                "__blank"
              )
            }
            icon="eye"
          />,
          <Action
            tooltip="Download CSV"
            onClick={() => downloadReviewsAsCsv(reviews, shopData?.storeName)}
            icon="download"
          />,
        ]}
      >
        <InfoBar icon="user" text={shopData?.storeDetails?.shop_owner} />
        <InfoBar icon="mail" text={shopData?.storeDetails?.email} />
        <InfoBar
          icon="map-pin"
          text={`${
            countries?.find(
              (country) => country?.key === shopData?.storeDetails?.country
            )?.val
          } (${shopData?.storeDetails?.country})`}
        />
      </Card>
    </div>
  );
};

export default ShopDetails;
