import { plans } from "../../../../common/constants";
import {
  alphabeticalSort,
  includeFilter,
  numericalSort,
} from "../../../common/utils";
import { ChangeCreditButton, ChangePlanButton } from "./ActionButtons";

export const storesColumns = [
  {
    key: "name",
    dataIndex: "shopName",
    title: "Shop Name",
    sorter: (a, b) => alphabeticalSort(a.shopName, b.shopName),
  },
  {
    key: "plan",
    title: "Plan",
    filters: Object.keys(plans).map((planKey) => {
      return { text: plans[planKey]?.name, value: planKey };
    }),
    onFilter: (value, record) => includeFilter(record.plan, value),
    render: (store) => <ChangePlanButton store={store} />,
  },
  {
    key: "credits",
    title: "Credits",
    sorter: (a, b) => numericalSort(a.credits, b.credits),
    render: (store) => <ChangeCreditButton store={store} />,
  },
];
